import Typed from 'typed.js';

document.addEventListener('DOMContentLoaded', function () {
    new Typed('#typed-text', {
        strings: [
            'Modernizing webshops',
            'Bringing amazing shopping experience',
            'Implementing AI tools',
            'Gaining insights from production to delivery',
        ],
        typeSpeed: 40,
        backSpeed: 30,
        loop: true,
    });
});
